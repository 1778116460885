import React from "react";
import home1 from '../../images/Group309.png'
import Layoutas from '../components/Layout';

const Index = () => {
  return (
    <Layoutas>
      <div style={{
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        backgroundImage: `url(${home1})`,
        justifyContent: 'center',
        // alignItems: 'flex-end',
        flexDirection: 'column',
        display: 'flex'
      }}>
        <div key="a" className="code-box-shape5" style={{ marginLeft: '40px' }}>
          <div style={{ fontSize: '10rem', color: '#fff', fontFamily: 'Kanit' }}>COMING SOON</div>
        </div>
      </div>
    </Layoutas >
  )
}

export default Index
